const apiUrl = 'https://api-staging.viessmann.com';

export const environment = {
    production: false,
    instana: true,
    statusPage: 'https://api-staging.viessmann.com/status/v1/apps/8d4sdgg7mps4/summary',
    auth: {
        appId: 'energy-label',
        baseUrl: apiUrl,
        registerBaseUrl: 'https://account-staging.viessmann.com',
    },
    apiUrl: apiUrl,
    everestApiUrl: apiUrl,
    translationUrl:
        'https://api-staging.viessmann.com/translations/v2/phraseapp-proxy/projects/89e6404bb2ed22771540cfa66647a3d5/locales/',
};
